import React, {Component} from 'react';
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import {ADD_SLIDE, SET_SLIDES} from "../../constants/ActionTypes";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {createSlide, finishEditingComponent, getSlides} from "../../lib/Fetch";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ComponentModal from "../ComponentsScreen/ComponentModal";

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    icon: {
        marginRight: theme.spacing.unit * 2,
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper,
    },
    heroContent: {
        maxWidth: 600,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    },
    heroButtons: {
        marginTop: theme.spacing.unit * 4,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    cardGrid: {
        padding: `${theme.spacing.unit * 8}px 0`,
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    deleteForeverIcon: {
        position: 'relative',
        top: 0,
        right: 0,
        float: 'right',
        marginTop: '-10px',
        marginRight: '-10px',
    },
    paper: {
        position: 'absolute',
        width: '50%',
        height: '50%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
});


class WidgetScreen extends Component {

    static propTypes = {
        account: PropTypes.object,
        setSlides: PropTypes.func,
        history: PropTypes.object,
        classes: PropTypes.object,
        addSlide: PropTypes.func,
    };

    state = {
        slides: [],
        updated: false,
        modalOpen: false,
        slide: {},
        selectedComponent: {Type: 'EmptyComponent'},
    };

    componentDidMount = () => {
        console.log('this.props.account', this.props.account);
        this.getSlides();
    };

    getSlides = () => {
        getSlides(this.props.account.WidgetScreen.Code)
            .then(body => {
                this.setState({...body});
            });
    };

    handleCloseModal = () => this.setState({modalOpen: false, selectedComponent: {Type: 'EmptyComponent'}});

    editComponent = (property, value) => {
        const comp = this.state.selectedComponent;
        comp[property] = value;
        this.setState({selectedComponent: comp});
    };

    createWidget = () => {
        this.setState({modalOpen: true});
    };

    saveWidget = (cb) => {
        createSlide(this.props.account.WidgetScreen.Code, 1)
            .then(body => {
                if (body.error) {
                    //TODO show some sort of error
                    return;
                }

                return finishEditingComponent({...this.state.selectedComponent, Position:1 }, body.slides.Id);
            })
            .then(body => {
                console.log('body', body);

                this.setState({modalOpen: false, selectedComponent: {Type: 'EmptyComponent'}});
                cb(true);
                this.getSlides();
            })
            .catch(err => {
                console.log('Save widget err', err);

                cb(false);
            })
    };

    render() {
        const {classes} = this.props;
        const {modalOpen, slide, selectedComponent} = this.state;

        return (
            <main>
                <div className={classes.heroUnit}>
                    <div className={classes.heroContent}>
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            Widget
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            Hier kunt u de widget voor uw locatie samenstellen.
                        </Typography>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={16} justify="center">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={this.createWidget}>
                                        Nieuwe widget toevoegen
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className={classNames(classes.layout, classes.cardGrid)}>
                    {
                        this.state.slides.map(slide => {
                            console.log('slide', slide);

                            return (
                                <div key={slide.Id}>
                                    <p> {slide.Components[0].Type}</p>
                                </div>
                            )
                        })
                    }

                </div>

                <ComponentModal
                    component={selectedComponent}
                    slide={slide}
                    handleClose={this.handleCloseModal}
                    open={modalOpen}
                    editComponent={this.editComponent}
                    reloadComponents={this.getComponents}
                    saveComponent={this.saveWidget}
                    removeComponent={this.removeComponent}
                />
            </main>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    slide: state.slide,
});

const mapDispatchToProps = dispatch => ({
    setSlides: slides => dispatch({type: SET_SLIDES, payload: slides}),
    addSlide: slide => dispatch({type: ADD_SLIDE, payload: slide}),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(WidgetScreen));
